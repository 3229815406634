import { AxiosError } from 'axios';

export type CommonState = {
  isLoading: boolean;
  error?: AxiosError<any>;
};

export type Pagination = {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number;
  links?: [];
};

export type ListParams = {
  page?: number;
  order?: 'asc' | 'desc';
  order_by?: string;
  per_page?: number;
};

export type DateInterval = {
  paid_at_from: Date;
  paid_at_to: Date;
};

export type DataListCourse = {
  id: number;
  title: string;
};

export const PLATFORMS = ['web', 'android', 'ios', 'huawei', 'landing', 'app'];

export const FORMATS_IMG = ['jpg', 'png', 'jpeg', 'JPG', 'PNG', 'JPEG'];
