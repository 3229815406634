import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CourseState, ILevel } from '../../@types/course';
import { INITIAL_PER_PAGE } from 'src/config';

const initialState: CourseState = {
  isLoading: false,
  courseList: {},
  allCourses: [],
  courseListParams: { per_page: INITIAL_PER_PAGE },
  groupList: {},
  goalsList: [],
  levelsList: [],
  groupsListParams: { per_page: INITIAL_PER_PAGE },
  faqList: {},
  faqListParams: { per_page: INITIAL_PER_PAGE },
  group: undefined,
};

const slice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    getGoalsListSuccess(state, action) {
      state.goalsList = action.payload;
    },

    getAllCoursesSuccess(state, action) {
      state.allCourses = action.payload;
    },

    getLevelsListSuccess(state, { payload }: PayloadAction<ILevel[]>) {
      state.levelsList = payload;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getCourseListSuccess(state, action) {
      state.isLoading = false;
      state.courseList = action.payload;
    },

    getCourseSuccess(state, action) {
      state.isLoading = false;
      state.course = action.payload;
    },

    getGroupListSuccess(state, action) {
      state.groupList = action.payload;
    },

    getGroupSuccess(state, action) {
      state.isLoading = false;
      state.group = action.payload;
    },

    getFaqListSuccess(state, action) {
      state.isLoading = false;
      state.faqList = action.payload;
    },

    getFaqSuccess(state, action) {
      state.isLoading = false;
      state.faq = action.payload;
    },

    setCourseListParams(state, action) {
      state.courseListParams = action.payload;
    },

    setFaqListParams(state, action) {
      state.faqListParams = action.payload;
    },

    clearCourse(state) {
      state.course = undefined;
    },

    clearCourseList(state) {
      state.courseList = {};
    },

    clearGroup(state) {
      state.group = undefined;
    },

    clearFaq(state) {
      state.faq = undefined;
    },

    clearFaqList(state) {
      state.faqList = {};
    },

    clearCourseParameters(state) {
      state.courseListParams = {};
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getGoalsListSuccess,
  getLevelsListSuccess,
  getCourseListSuccess,
  getCourseSuccess,
  getAllCoursesSuccess,
  setCourseListParams,
  getGroupListSuccess,
  getFaqListSuccess,
  getFaqSuccess,
  getGroupSuccess,
  clearCourse,
  clearGroup,
  clearFaq,
  clearFaqList,
  clearCourseParameters,
  clearCourseList,
  setFaqListParams,
} = slice.actions;
